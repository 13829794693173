// * flex grow items-center justify-between font-mono font-normal
.m--financial-text {
  display: flex;
  align-items: center;
  font-family: var(--font-mono);
  font-variation-settings: var(--font-variation--regular);
  justify-content: space-between;
  flex-grow: 1; // * stretch to fill its container (e.g. a cell in a table)
}

// * text-red
.m--negative-number {
  color: var(--color-error);
}

// * grow-0 text-gray pr-[2%]
.m--symbol {
  flex-grow: 0;
  color: var(--gray);
  padding-right: 2%;
}

.m--value {
  flex-grow: 1;
  text-align: right;
}
