.m--nav-label {
  line-height: 19.2px;
  color: var(--graphite);
  display: flex;
  align-items: center;
}

.m--icon,
.m--icon-placeholder {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

.m--display-label {
  flex-grow: 1;
}
