
.tabs {
  .tabs-header {
    display: flex;
    flex-direction: row;
    margin-bottom:0.5rem;

    .tabs-header-tab {
      //padding:0.5rem;
      cursor:pointer;

      // &__active {
      //   border-bottom: 2px solid var(--color-primary)
      // }
    }
  }

  .tabs-content {
    width:100%;
  }
}