.m--icon-button {
  font-size: 0;
  padding-left: 7px;
  padding-right: 7px;
  border-radius: 50%;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1), 0px 8px 8px -3px rgba(0, 0, 0, 0.2);

  *:not(svg) { width: 0; }
  svg { 
    width: 16px;
    height: 16px;
    margin: 0; 
  }

  &--small {
    padding-left: 4px;
    padding-right: 4px;

    svg {
      width: 12px;
      height: 12px;
    }
  }

  &--large {
    padding-left: 11px;
    padding-right: 11px;

    svg { 
      width: 20px; 
      height: 20px; 
    }
  }
}
