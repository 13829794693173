@keyframes fade-in {
  0% { opacity: 0 }
  100% { opacity: 1 }
}

@keyframes fade-out {
  0% { opacity: 1 }
  100% { opacity: 0 }
}

$wrapperWidth: 160px;

.wrapper {
  position: fixed;
  z-index: 999;
  width: $wrapperWidth;
  height: $wrapperWidth;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  pointer-events: none;
}

.wrapper-align-top {
  align-items: flex-start;
  transform: translateX(calc(#{$wrapperWidth} / 2 * -1));
  
  .tool-tip {
    text-align: center;

    &:after {
      transform: rotate(45deg);
      top: calc((var(--caret-size) / 2) * -1);
    }
  }
}

.wrapper-align-bottom {
  align-items: flex-end;
  transform: translateY(calc(-1 * #{$wrapperWidth})) translateX(calc((#{$wrapperWidth} / 2) * -1));
  
  .tool-tip {
    text-align: center;
  }
}

.wrapper-align-left {
  justify-content: flex-start;
  transform: translateY(calc((#{$wrapperWidth} / 2) * -1));
  
  .tool-tip {
    text-align: left;

    &:after {
      left: calc((var(--caret-size) / 2) * -1);
      transform: rotate(-45deg);
      margin-left: 0;
      margin-top: calc((var(--caret-size) / 2) * -1);
      top: 50%;
    }
  }
}

.wrapper-align-right {
  justify-content: flex-end;
  transform: translateX(calc(-1 * #{$wrapperWidth})) translateY(calc((#{$wrapperWidth} / 2) * -1));
  
  .tool-tip {
    text-align: left;

    &:after {
      left: calc(100% - (var(--caret-size) / 2));
      transform: rotate(135deg);
      margin-left: 0;
      margin-top: calc((var(--caret-size) / 2) * -1);
      top: 50%;
    }
  }
}

.tool-tip {
  --caret-size: 12px;

  border-radius: 1.6px;
  font-size: 12px;
  padding: 4.8px 8px;
  text-align: center;
  background: #313341;
  color: #fff;
  animation: fade-in forwards 0.1s;
  filter: drop-shadow(0px 2px 4px rgba(#000, 0.25));
  position: relative;

  &:after {
    width: var(--caret-size);
    height: var(--caret-size);
    background: #313341;
    border-radius: 1.6px 0 0 0;
    transform: rotate(-135deg);
    content: '';
    left: 50%;
    margin-left: calc((var(--caret-size) / 2) * -1);
    position: absolute;
    top: calc(100% - (var(--caret-size) / 2));
    z-index: 1;
  }

  &--hiding {
    animation: fade-out forwards 0.1s;
  }
}

.tool-tip-inner {
  position: relative;
  z-index: 2;
}
