.m--hero-kpi-card {
  // flex-direction: column;
  align-items: center;
  max-width: 100%;
  // min-width: 150px;
  min-height: 145px;
  background: var(--gray-lightest);
  box-shadow: none;
}

.m--icon-wrapper {
  background: var(--white);
  border-radius: 100px;
  height: 42px;
  width: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--black);
}

* + .m--value-wrapper {
  margin-left: 12px;
}

.m--value-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.m--other-wrapper {
  margin: 0 5px;
}

.m--help-text {
  margin-left: 4px;
  vertical-align: text-top;
  cursor: pointer;
  & > path {
    stroke: var(--gray-darker);
  }
}

.m--primary {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.m--secondary {
  margin-top: 2px;
  line-height: 14.4px;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;

  span {
    flex: 1 0 0;
  }

  &--long {
    overflow: hidden;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &--green {
    color: var(--color-success);
  }

  &--red {
    color: var(--color-error);
  }

  svg {
    flex: 0 0 16px;
    height: 16px;
    width: 16px;
    margin-left: 4px;
  }
}
