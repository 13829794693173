.m--Anchor {
  text-decoration: none;
  display: inline-flex;
  align-items: center;

  .m--Anchor__text {
    border-bottom: 1px dashed var(--gray-darker-20);
  }
  &--has-icon {
    .m--Anchor__text {
      margin-right: 5px;
    }

    .m--Anchor__icon {
      width: 16px;
      height: 16px;
    }
  }
}
