.chart {
  width: 100%;
  height: 20rem;
  position: relative;
  overflow: hidden;

  .loading-spinner {
    --size: 3rem;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    color: currentColor;
    opacity: 0.5;
  }

  svg, canvas {
    width: 100%;
    overflow: visible;
  }
}
