.spooky-wrapper {
  border: 1px solid transparent;
  border-radius: 8px;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  overflow: hidden;

  &:hover {
    .spooky-icon {
      color: var(--color-primary);
    }
  }

  &--expanded {
    cursor: text;
    padding: 0 6px 0 10px;
    border-color: var(--color-primary);
    box-shadow: inset 2px 2px 2px rgba(0, 0, 0, 0.04);

    .spooky-icon-wrapper {
      margin-right: 6px;
      cursor: default;

      .spooky-icon {
        color: var(--color-primary);
      }
    }
  }

  &--fullWidth {
    width: 100%;
  }

  &--disabled {
    cursor: default;

    .spooky-icon-wrapper {
      .spooky-icon {
        opacity: 0.7;
      }
    }

    .spooky-clear-button {
      opacity: 0.7;
    }
  }
}

.spooky-icon-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 5px 0;
}

.spooky-icon {
  color: va(--graphite);
  width: 18px;
  height: 18px;
  flex-basis: 18px;
  margin: 0;
}

.spooky-input {
  min-height: 0;
  box-shadow: none;
  border-color: transparent;
  padding: 0;
  border-radius: 0;
  width: 0;
  transition: 0.1s all ease-in-out;
  height: 18px;

  &--expanded {
    width: 210px;
    border-right: 1px solid var(--color-border);
    padding-right: 6px;
    margin-right: 6px;
  }

  &--fullWidth {
    width: 100%;
  }
}

.spooky-clear-button {
  padding: 0;
  border-width: 0;

  svg {
    width: 18px;
    height: 18px;
  }
}
