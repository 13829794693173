.histogram-chart {
  .histogram-chart__bars {
    display: flex;
    align-items: baseline;

    & > div {
      div {
        background: var(--gray-lightest);
        transition: 0.3s;
        margin: 0.5px;
        border-radius: 2px;
        height: 100%;
      }

      &.active > div {
        background: var(--color-primary);
      }
    }
  }

  .histogram-chart__values {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 1rem 0 0;

    .histogram-chart__values__separator {
      height: 1px;
      width: 20px;
      background: var(--true-black);
    }
  }
}
