.m--subheader {
  margin-bottom: 16px;
  position: relative;
  padding-left: 15px;
  display: flex;
  align-items: center;
  width: 100%;

  span {
    position: relative;
    z-index: 2;
    margin-right: 15px;
  }

  &:before {
    content: '';
    position: absolute;
    z-index: 1;
    width: 3px;
    height: 1em;
    margin: auto;
    left: 0;
    top: 0;
    bottom: 0;
    background: var(--color-primary);
  }

  &:after {
    content: '';
    flex-grow: 1;
    height: 2px;
    background: var(--gray-darker-10);
  }

  svg {
    margin: 0 4px;
  }
}
