$bg-gradient: linear-gradient(327.19deg, #fbfcfe 5.16%, #fefefe 91.23%);

.m--select-input {
  font-family: 'Barlow';
  height: 32px;
  display: inline-flex;
  align-items: center;
  position: relative;
  padding: 4px 8px;
  border: 1px solid var(--color-border);
  border-radius: 8px;
  background: $bg-gradient;
  transition: all 0.1s ease-in-out;
  box-shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.05);
  overflow: hidden;

  select {
    -webkit-appearance: none;
    appearance: none;
    border: none;
    flex: 1 0 0;
    width: 100%;
    outline: none;
    color: inherit;
    font-size: 14px;
    font-variant-numeric: tabular-nums;
    text-align: left;
    cursor: pointer;
    background-color: transparent;
    height: 100%;
    color: var(--gray-darker);
    transition: 0.1s all ease-in-out;
    position: relative;
    z-index: 1;
    padding-right: 16px;

    option {
      color: var(--true-black);
    }
  }

  svg {
    position: absolute;
    z-index: 0;
    width: 16px;
    height: 16px;
    color: var(--gray-darker);
    transition: 0.1s all ease-in-out;
    top: 0;
    bottom: 0;
    margin: auto;

    &:first-child {
      left: 8px;
    }

    &:last-child {
      right: 8px;
    }
  }

  svg + select {
    padding-left: 24px;
  }

  &--multiple {
    height: auto;
    padding: 0;
    background: none;

    select {
      display: block;
      max-width: 100%;
      text-align: left;
      background: $bg-gradient;
      padding: 0;

      option {
        padding: 6px;
        color: var(--input-multi-select-text-color);

        &:nth-child(even) {
          background: var(--input-multi-select-background-alternate);
        }

        &:checked {
          background: var(--input-selected-value-background);
          color: var(--input-selected-value-text-color);
        }
      }

      &:focus {
        option {
          &:checked {
            background: var(--input-selected-value-background);
            color: var(--input-selected-value-text-color);
          }
        }
      }
    }
  }

  &--focus {
    border-color: rgba(var(--gray-dark), 0.4); // $focusBorder
  }

  &--dirty {
    select {
      color: var(--graphite);
    }
  }

  &--disabled {
    opacity: 0.7;

    select {
      opacity: 1;
      cursor: default;
    }
  }

  &--loading {
    opacity: 1;

    select,
    svg {
      opacity: 0.5;
      overflow: hidden;
    }
  }

  &--with-label {
    .m--label {
      pointer-events: none;
      color: var(--gray-darker);
      font-size: 14px;
    }

    select {
      color: var(--graphite);
      font-weight: 700;
    }

    &.m--select-input--dirty {
      .m--label {
        &:after {
          content: ':\00a0';
        }
      }
    }

    &:not(.m--select-input--dirty) {
      select {
        width: 0;
      }
    }
  }
}

.m--loading-overlay {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
