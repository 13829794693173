.m--kpi-card-button {
  &:not(.m--kpi-card-button--disabled) {
    &:hover,
    &:focus {
      background: var(--white);

      .m--label-inner {
        border-bottom-style: solid;
      }

      .m--label svg {
        color: var(--gray-dark);
      }
    }
  }
}

.m--label {
  display: flex;
  align-items: center;

  svg {
    transition: 0.1s all ease-in-out;
    color: var(--gray);
    margin-left: 3px;
    flex-basis: 24px;
  }
}

.m--label-inner {
  transition: 0.1s all ease-in-out;
  border-bottom: 1px dashed var(--gray-dark);
  text-overflow: ellipsis;
  overflow: hidden;
}
