.text-input {
  display: inline-flex;
  align-items: center;
  position: relative;
  min-height: 32px;
  padding: 4px 8px;
  border: 1px solid var(--color-border);
  border-radius: 8px;
  background: var(--white);
  transition: all 0.1s ease-in-out;
  box-shadow: inset 2px 2px 2px rgba(0, 0, 0, 0.04);

  & > svg {
    margin-right: 8px;
    width: 16px;
    height: 16px;
    flex: 0 0 16px;
    color: var(--gray-darker);
    transition: 0.1s all ease-in-out;
  }

  &--number {
    padding-top: 0;
    padding-bottom: 0;
  }

  input {
    flex: 1 0 0;
    max-width: 100%;
    width: auto;
    border: 0;
    padding: 0;
    outline: none;
    background: transparent;
    font-family: 'Barlow';
    font-size: 14px;
    color: var(--graphite);
    transition: 0.1s all ease-in-out;

    -webkit-touch-callout: auto;
    -webkit-user-select: auto;
    -khtml-user-select: auto;
    -moz-user-select: auto;
    -ms-user-select: auto;
    user-select: auto;

    &::-webkit-input-placeholder {
      transition: 0.1s all ease-in-out;
      color: var(--gray-darker);
    }

    &::-moz-placeholder {
      transition: 0.1s all ease-in-out;
      color: var(--gray-darker);
    }

    &:-ms-input-placeholder {
      transition: 0.1s all ease-in-out;
      color: var(--gray-darker);
    }

    &:-moz-placeholder {
      transition: 0.1s all ease-in-out;
      color: var(--gray-darker);
    }

    &[type='number'] {
      -webkit-appearance: textfield;
      -moz-appearance: textfield;
      appearance: textfield;

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
      }
    }
  }

  & > svg + input {
    max-width: calc(100% - 24px);
  }

  &--focus {
  }

  &--dirty {
  }

  &--disabled {
    opacity: 0.7;
  }

  &--loading {
    opacity: 1;

    input {
      opacity: 0.5;
    }
  }
}

.controls {
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    padding: 0;

    svg {
      width: 14px;
      height: 14px;
    }

    &:first-child {
      margin-bottom: 2px;
    }
  }
}

.right-label {
  color: var(--gray-dark);
  font-size: 14px;
  margin-left: 6px;
  white-space: nowrap;
}
.left-label {
  color: var(--gray-dark);
  font-size: 14px;
  margin-right: 6px;
  white-space: nowrap;
}

.input-container {
  flex: 1 0 0;
  max-width: 100%;
  position: relative;
  display: flex;

  input {
    position: relative;
    z-index: 1;
  }
}

.loading-overlay {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
