.m--type {
  &__high,
  &__low {
    color: var(--white);
  }

  &__high {
    background-color: var(--red);
  }
  &__moderate {
    background-color: var(--yellow);
  }
  &__low {
    background-color: transparent;
  }
  &__closed {
    background-color: var(--grey-30);
    color: var(--white);
  }
}

.m--size {
  &__large {
    padding: 0.2em 0.8em;
    border-radius: 1em;
    font-size: 16px;
  }
  &__default {
    padding: 0.2em 1em;
    border-radius: 1em;
    font-size: 16px;
  }
  &__small {
    padding: 0.3em 1em;
    border-radius: 1em;
    font-size: 12px;
  }
}
