.drilldown-cell {
  display: flex;
  gap: 3px;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .drilldown-icon {
    width: 12px;
  }
}
