.radio-input {
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  input {
    position: absolute;
    left: -100vh;
  }

  input ~ svg {
    width: 20px;
    height: 20px;
    transition: 0.1s all ease-in-out;
    overflow: visible;

    --radio-inner: transparent;
    --radio-border: var(--gray); // $inactiveBorder
  }

  input:checked + svg {
    --radio-inner: var(--color-primary);
    --radio-border: var(--color-primary);
  }

  &:hover input:not(:checked):not(:disabled) + svg {
    --radio-border: var(--color-primary);
  }

  &--disabled {
    cursor: default;
  }

  input:disabled + svg {
    --radio-inner: transparent;
    --radio-border: var(--gray); // $inactiveBorder
  }

  input:disabled:checked + svg {
    --radio-inner: var(--gray); // $inactiveBorder
    --radio-border: var(--gray); // $inactiveBorder
  }

  span {
    margin-left: 8px;
  }
}
