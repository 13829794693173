.m--kpi-card {
  max-width: 100%;
  min-width: 150px;

  > div {
    width: 100%;
    overflow: hidden;
    flex-direction: column;
    align-items: flex-start;
  }
}

.m--label {
  font-size: 14px;
  margin-bottom: 4px;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
  width: 100%;
  text-overflow: ellipsis;

  .m--help-text {
    margin-left: 4px;
    vertical-align: text-top;
    cursor: pointer;
    & > path {
      stroke: var(--gray-darker-40);
    }
  }
}

.m--value-wrapper {
  display: flex;
  align-items: center;

  > .m--stacked-vertically {
    overflow: hidden;

    > div {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.m--icon-wrapper,
.m--other-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
}

.m--primary {
  width: 100%;
  font-variation-settings: 'wght' var(--variable-font-weight--bold), 'wdth' 500;
  font-size: 26px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &--green {
    color: var(--green);
  }

  &--red {
    color: var(--red);
  }

  &--true-black {
    color: var(--true-black);
  }
}

.m--secondary {
  margin-top: 4px;
  font-weight: 700;
  font-size: 12px;
  line-height: 16.8px;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;

  &--long {
    overflow: hidden;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &--green {
    color: var(--green);
  }

  &--red {
    color: var(--green);
  }

  &--true-black {
    color: var(--color-text);
  }

  svg {
    flex: 0 0 16px;
    height: 16px;
    width: 16px;
    margin-left: 4px;
  }
}
