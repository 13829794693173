.button {
  //   font-family: 'Barlow';
  appearance: none;
  border-radius: 8px;
  border: none;
  outline: none;
  font-weight: 700;
  font-size: 14px;
  padding-left: 24px;
  padding-right: 24px;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  border: 1px solid transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;

  &[disabled] {
    cursor: default;
  }

  svg {
    width: 0;
    height: 0;
  }

  &--small {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  &--medium {
    padding-top: 7px;
    padding-bottom: 7px;
  }

  &--large {
    font-size: 16px;
    padding-top: 11px;
    padding-bottom: 11px;
  }

  &--has-icon {
    padding-left: 14px;
    padding-right: 14px;
    font-weight: 500;

    svg {
      width: 20px;
      height: 20px;
      margin: 0 6px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &--primary {
    background: var(--color-primary);
    color: var(--white);
    box-shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.05);

    &:hover,
    &:focus {
      background: var(--color-primary-dark);
    }

    &[disabled] {
      background: var(--gray);

      .loading-overlay {
        background: var(--gray);
      }
    }
  }

  &--secondary {
    background: linear-gradient(327.19deg, #fbfcfe 5.16%, #fefefe 91.23%);
    border-color: var(--gray-darker-10);
    color: var(--graphite);
    box-shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.05);

    &:hover,
    &:focus {
      background: linear-gradient(327.19deg, #edf1f7 5.16%, #fafbfd 91.23%);
      border-color: rgba(172, 175, 181, 0.4);
    }

    &[disabled] {
      color: var(--gray-dark);
      background: linear-gradient(327.19deg, #fbfcfe 5.16%, #fbfcfe 91.23%);
      border-color: rgba(172, 175, 181, 0.3);

      .loading-overlay {
        background: linear-gradient(327.19deg, #fbfcfe 5.16%, #fbfcfe 91.23%);
      }
    }
  }

  &--active {
    color: var(--color-primary);
    background: rgba(206, 220, 252, 0.25);
    border-color: var(--color-primary);

    &:hover,
    &:focus {
      background: rgba(206, 220, 252, 0.6);
    }

    &[disabled] {
      color: var(--gray-dark);
      background: linear-gradient(327.19deg, #fbfcfe 5.16%, #fbfcfe 91.23%);
      border-color: rgba(172, 175, 181, 0.3);

      .loading-overlay {
        background: var(--gray-dark);
      }
    }
  }

  &--ghost {
    background: var(--white);
    border-width: 1px;
    padding-left: 0;
    padding-right: 0;

    &--active {
      border-color: var(--color-primary);
    }

    &:hover,
    &:focus {
      color: var(--color-primary);
    }

    &[disabled] {
      background: var(--white);
      color: var(--gray-dark);

      .loading-overlay {
        background: var(--white);
      }
    }
  }
}

.loading-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
