.m--copy-stat {
  padding: 6px 12px;

  span {
    font-weight: normal;
  }

  strong {
    margin-left: 4px;
    font-weight: 700;
  }
}
