.fancy-select-input {
  position: relative;
  display: inline-flex;

  &--loading {
    opacity: 1;
    pointer-events: none;
  }


  .loading-overlay {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

}
