// REVIEW >> use DS
$box-shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.05);
$box-shadow-primary: $box-shadow;

$bg-ghost: var(--white);
$bg-active: rgba(206, 220, 252, 0.25);
$bg-active-focused: rgba(206, 220, 252, 0.6);
$bg-disabled: linear-gradient(327.19deg, #fbfcfe 5.16%, #fbfcfe 91.23%);
$bg-secondary: linear-gradient(327.19deg, #fbfcfe 5.16%, #fefefe 91.23%);
$bg-focused: linear-gradient(327.19deg, #edf1f7 5.16%, #fafbfd 91.23%);
$bg-secondary-focused: $bg-focused;

$border-disabled: rgba(172, 175, 181, 0.3);
$border-secondary: rgba(172, 175, 181, 0.4);

$padding-sm: 4px 24px;
$padding-md: 7px 24px;
$padding-lg: 11px 24px;

.button {
  //   font-family: 'Barlow';
  appearance: none;
  border-radius: 8px;
  border: none;
  outline: none;
  // font-weight: 700;
  font-variation-settings: var(--font-variation--bold);
  font-size: 14px;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  border: 1px solid transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;

  &[disabled] {
    background: $bg-disabled;
    cursor: default;
  }

  svg {
    width: 0;
    height: 0;
  }

  &--small {
    padding: $padding-sm;
  }

  &--medium {
    padding: $padding-md;
  }

  &--large {
    font-size: var(--text-md);
    padding: $padding-lg;
  }

  &--has-icon {
    padding-left: 14px;
    padding-right: 14px;
    // font-weight: 500;
    font-variation-settings: var(--font-variation--medium);

    svg {
      width: 20px;
      height: 20px;
      margin: 0 6px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &--primary {
    background-color: var(--color-primary) !important;
    color: var(--white);
    box-shadow: $box-shadow-primary;

    &:hover,
    &:focus {
      background: var(--color-primary-dark);
    }

    &[disabled] {
      background: var(--gray);

      .loading-overlay {
        background: var(--gray);
      }
    }
  }

  &--secondary {
    background: $bg-secondary;
    border-color: var(--gray-darker-100);
    color: var(--graphite);
    box-shadow: $box-shadow;

    &:hover,
    &:focus {
      background: $bg-secondary-focused;
      border-color: $border-secondary;
    }

    &[disabled] {
      color: var(--gray-dark);
      background: $bg-disabled;

      .loading-overlay {
        background: $bg-disabled;
      }
    }
  }

  &--active {
    color: var(--color-primary);
    background: $bg-active;
    border-color: var(--color-primary);

    &:hover,
    &:focus {
      background: $bg-active-focused;
    }

    &[disabled] {
      color: var(--gray-dark);
      background: $bg-disabled;
      border-color: $border-disabled;

      .loading-overlay {
        background: var(--gray-dark);
      }
    }
  }

  &--ghost {
    background: $bg-ghost;
    border-width: 1px;
    padding-left: 0;
    padding-right: 0;

    &--active {
      border-color: var(--color-primary);
    }

    &:hover,
    &:focus {
      color: var(--color-primary);
    }

    &[disabled] {
      background: $bg-ghost;
      color: var(--gray-dark);

      .loading-overlay {
        background: $bg-ghost;
      }
    }
  }
}

.loading-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--true-black);

  // svg {
  //   width: 30px;
  //   height: 30px;
  // }
}
