
.arc-meter {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.indicator {
  background: var(--white);
  box-shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.05);
  position: absolute;
  border-radius: 50%;
}
