$nav-item-bg: hsl(222, 88%, 90%);

.m--nav-item {
  margin-left: 16px;
  display: inline-flex;
  line-height: 16.8px;
  border-radius: 12px;
  padding: 2px 10px 3px;
  cursor: pointer;
  transition: 0.1s all ease-in-out;
  text-decoration: none;

  &:hover,
  &:focus {
    background: var(--white);
  }

  &:global(.active) {
    color: var(--color-primary);
    background: $nav-item-bg;
  }
}

.m--label {
  flex-grow: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 10px;
}
