// hides browser default checkbox input but keeps it visible to screen readers
.m--checkbox__input {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.m--checkbox__wrapper {
  display: flex;
  align-items: center;
  gap: 6px;
}

.m--checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  color: var(--white);


  &.checked {
    &.default {
      background-color: var(--text-color-primary);
    }

    &.red {
      background-color: var(--color-error);
    }

    &.yellow {
      background-color: var(--color-warning);
    }

    &.green {
      background-color: var(--color-success);
    }
  }
}

.default {
  border-color: var(--color-primary);
}

.red {
  border-color: var(--color-error);
}

.yellow {
  border-color: var(--color-warning);
}

.green {
  border-color: var(--color-success);
}
