.m--copy-icon {
  padding: 6px;

  span {
    // font-weight: normal;
    font-variation-settings: var(--font-variation--regular);
  }

  strong {
    margin-left: 4px;
    // font-weight: 700;
    font-variation-settings: var(--font-variation--bold);
  }

  &:hover {
    cursor: pointer;
  }
}

.rounded {
  border-radius: 50%;
}
