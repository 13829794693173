.m--fallback {
  padding: 24px 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  color: var(--gray-dark);
  text-align: center;

  svg {
    margin-top: 24px;
    max-width: 100%;
  }
}

.m--header {
  font-size: clamp(24px, 5vw, 36px);
  margin-bottom: 12px;
}
