.m--number-text {
  text-align: right;
  font-family: var(--font-mono);

  .m--bubble {
    border-radius: 12px;
    padding: 2px 10px;
    background: var(--gray-darker);
    color: var(--white);
  }
  .m--percent-sign {
    color: var(--gray);
    padding-left: 6px;
  }
}
