.m--wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  // REVIEW >> look for and remove display inline elsewhere
  // & * {
  //     display: inline;
  // }
}

.m--controls {
  display: flex;
  align-items: center;
  gap: 7px;
}

.m--divider {
  width: 1px;
  height: 30px;
  padding: 0;
  margin: 0 10px;
}
