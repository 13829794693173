$box-shadow-top: 0px -2px 6px -1px rgba(0, 0, 0, 0.02);
$box-shadow-bottom: 0px 4px 10px -2px rgba(0, 0, 0, 0.05);
$bg-overlay: rgba(255, 255, 255, 0.8);

.m--card {
  padding: 16px;
  background: var(--white);
  border-radius: 12px;
  display: inline-flex;
  transition: 0.1s all ease-in-out;
  position: relative;
  overflow: hidden;

  .loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: $bg-overlay;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.card-shadow {
  box-shadow: $box-shadow-top, $box-shadow-bottom;
}
