@keyframes is-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.m--loading-spinner {
  --size: 32px;

  color: currentColor;
  display: inline-block;
  position: relative;
  width: var(--size);
  height: var(--size);
  animation-name: is-loading;
  animation-duration: 2500ms;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.5, 0, 0.5, 1);

  &--small {
    --size: 16px;
  }

  &--medium {
    --size: 32px;
  }

  &--large {
    --size: 48px;
  }
}

.svg{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}