$bg-focused: linear-gradient(327.19deg, #edf1f7 5.16%, #fafbfd 91.23%);
$bg-disabled: linear-gradient(327.19deg, #fbfcfe 5.16%, #fbfcfe 91.23%);

.card-button {
  outline: none;

  &:not(.card-button--disabled) {
    cursor: pointer;

    &:hover,
    &:focus {
      background: $bg-focused;
    }
  }

  &--disabled {
    color: var(--gray-dark);
    cursor: default;
    background: $bg-disabled;

    .loading-overlay {
      background: $bg-disabled;
    }
  }
}
