$bg-active: rgba(206, 220, 252, 0.25);

.m--button-group {
  display: inline-flex;
  align-items: center;
}

.m--button {
  padding-left: 8px;
  padding-right: 8px;

  &:not(:first-child):not(&--ghost-with-icon) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &:not(:last-child):not(&--ghost-with-icon) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  & + .m--button {
    margin-left: -1px;
  }
  
  svg {
    width: 18px;
    height: 18px;
  }

  &--ghost-with-icon {
    padding: 5px 11px;
    border-radius: 8px;
    
    margin-right: 2px;
    margin-left: 0;

    &:last-child {
      margin-right: 0;
    }

    &.button--active {
      background: $bg-active;
    }
  }
}

.m--spooky-input {
  margin-right: 4px;
  margin-left: 2px;
}
