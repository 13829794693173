.m--date-text {
  display: flex;
  align-items: center;
}

.m--symbol {
  width: 20px;
  margin-right: 8px;
}

.m--value {
  flex-grow: 1;
}
