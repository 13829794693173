.funnel-category {
  display: flex;
  align-items: center;
}

.category-label {
  flex-grow: 1;
  flex-basis: 124px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.value-wrapper {
  margin: 0 10px;
  flex-basis: 162px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.value {
  height: 28px;
}

.percent {
  flex-grow: 1;
  flex-basis: 84px;
  text-align: right;
}
