.toggle-input {
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  input {
    position: absolute;
    left: -100vw;
  }

  input ~ svg {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    transition: 0.1s all ease-in-out;
  }

  &--disabled {
    cursor: default;
  }

  &--reversed {
    .toggle-input-toggler:before {
      left: 20px;
    }
    input:checked ~ .toggle-input-toggler:before {
      left: 2px;
    }
  }
}

.toggle-input-toggler {
  --thumb-color: var(--white);

  width: 38px;
  height: 20px;
  border-radius: 11px;
  position: relative;
  background: var(--bw-grey-300, #e6e8ed);
  border: 1px solid #ced1d7;
  margin-right: 8px;

  &--always-blue {
    background: var(--color-primary);
  }

  &:before {
    content: '';
    width: 20px;
    height: 20px;
    top: -1px;
    left: -1px;
    border: 1.2px solid #ced1d7;
    border-radius: 50%;
    position: absolute;
    background: var(--thumb-color);
    box-shadow: 0px 2px 1px -1px rgba(18, 22, 29, 0.04), 0px 2px 4px -1px rgba(18, 22, 29, 0.05);
    transition: 0.1s all ease-in-out;
  }
}

input:checked + .toggle-input-toggler {
  background: var(--color-primary);
  border-color: var(--Accent-Blue-700, #284cc5);

  &:before {
    left: 17px;
    border-color: var(--Accent-Blue-700, #284cc5);
  }
}

input:disabled + .toggle-input-toggler {
  background: var(--gray-lightest);
}

input:disabled:checked + .toggle-input-toggler {
  background-color: var(--accent-blue-600-primary, #335eee);
  border-color: var(--Accent-Blue-700, #284cc5);
  opacity: 0.5;
}

input:disabled:not(:checked) + .toggle-input-toggler {
  background: var(--bw-grey-300, #e6e8ed);
  border-color: var(--bw-grey-500, #ced1d7);
  &:before {
    background: var(--bw-grey-500, #ced1d7);
  }
}
